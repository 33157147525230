@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew:300,400,500,600,700,800");

* {
  direction: rtl;
  font-family: "Noto Sans Hebrew", cursive !important;
  /* box-sizing: border-box; */
}

html,
#root {
  height: 100%;
  /* overflow: hidden; */
}

.page-content {
  min-height: calc(100vh - 127px);
  margin-top: 90px;
  margin-bottom: 60px;
  /* overflow-y: auto;
  overflow-x: hidden !important; */
}

.page-content-menu-tab {
  min-height: calc(100vh - 187px);
  margin-top: 65px;
}

.page-content-botton {
  min-height: calc(100vh - 197px);
}

.daybook-wrap thead {
  position: relative;
  z-index: 0;
}

@media (max-width: 599px) {
  .page-content {
    height: auto;
    margin-bottom: 0 !important;
    overflow-y: auto;
    overflow-x: hidden !important;
    min-height: calc(100vh - 195px);
  }
}

.MuiTablePagination-actions {
  flex-direction: row !important;
}

.MuiTablePagination-actions .MuiButtonBase-root {
  rotate: 180deg !important;
}

body {
  margin: 0;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-pagination .MuiTablePagination-selectLabel {
  display: none;
}

.custom-pagination .MuiInputBase-root {
  margin-left: auto;
}

.custom-pagination .MuiInputBase-root > div {
  border: 1px solid #d0d4d9;
  border-radius: 5px;
  background-color: #ffffff;
  padding-left: 35px;
  padding-right: 10px !important;
}

.custom-pagination .MuiInputBase-root .MuiSelect-icon {
  left: 5px;
  right: auto;
}

.MuiAutocomplete-inputRoot {
  padding-right: 15px !important;
  padding: 3px !important;
}

.custom-pagination .MuiTablePagination-spacer {
  flex: unset;
}

.MuiModal-root {
  position: absolute;
  width: 100%;
  height: 100%;
}

.custom-pagination {
  flex-grow: 1;
}

.custom-pagination > div {
  padding-right: 2px;
  padding-left: 2px;
}

.custom-pagination .MuiTablePagination-actions {
  flex-direction: row-reverse;
  display: flex;
  margin-left: 0 !important;
}

.button-lists button {
  min-width: 1px;
}

.button-lists button:last-child {
  padding-left: 0;
  padding-right: 3px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.placeholder-Padding .MuiSelect-select {
  padding-right: 15px !important;
  text-align: left !important;
}

.custom-select .MuiSelect-select {
  padding-right: 15px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#progressionImg {
  filter: grayscale(100%);
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.notification-class .MuiPaper-root {
  border-radius: 8px;
}

.notification-class ul.MuiMenu-list {
  padding: 0;
  border-radius: 8px;
}

.notification-class ul.MuiMenu-list li:last-child {
  border-bottom: 0;
}

#menu-appBar .MuiMenu-paper {
  left: auto;
  width: calc(100% - 35px);
  right: 0;
  border-radius: 0 0 0 30px;
  box-shadow: none;
  border: 1px solid #dfe2e5;
}

#menu-appBar .MuiMenu-paper li:not(:first-child) {
  border-top: 1px solid #dfe2e5;
}

#menu-appBar.MuiModal-hidden + .main-menu {
  visibility: hidden;
  opacity: 0;
}

#menu-appBar.main-menu + div:not(.MuiModal-root) > div {
  width: max-content !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.header-logo {
  width: 80px;
}

.MuiContainer-root .library-main {
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.library-main .MuiTabs-fixed {
  margin-top: 20px;
}

@media (min-width: 600px) {
  .MuiContainer-root .library-main {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  .header-logo {
    width: 90px;
  }

  .dropDownMenu.course-dropdown > span + div > div > div {
    max-width: 135px;
  }

  .MuiContainer-root .library-main {
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
  }

  #atomicWrap > div {
    width: 100%;
  }
}

.custom-math,
.custom-math * {
  direction: ltr;
  font-family: "Noto Sans Hebrew", serif !important;
}

.custom-math p img {
  vertical-align: 0 !important;
}

.modal-parent:focus,
.modal-parent:focus-visible {
  box-shadow: none;
  border: 0;
  outline: none;
}

.modal-close svg {
  width: 1.3em;
  height: 1.3em;
}

.modal-parent .demo-simple-select {
  padding-top: 0;
}

.modal-parent .demo-simple-select > div {
  padding: 9px 14px;
  background-color: #fff;
}

/* Style the progress bar container */
.progress-bar-container {
  width: calc(100% - 110px);
  height: 20px;
  padding: 15px 60px 15px 50px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

/* Style the progress bar */
.progress-bar-container .progress-bar {
  height: 20px;
  margin: 0;
  background-color: #3397db;
}

.no-answer-selected .progress-bar-container .progress-bar {
  background-color: #3397db !important;
}

.progress-bar-container .success-circle {
  border-color: #33cd75;
  background-color: #33cd75;
  color: #fff;
}

@media (max-width: 768px) {
  .react-player,
  .react-player iframe {
    height: 250px !important;
    width: 100% !important;
  }
}

.step-indicator-list span svg {
  width: 17px;
  height: 17px;
}

:not(.custom-math) > p > math * {
  direction: ltr;
  font-family: math, "Times New Roman", serif !important;
}

.table-attendance-row:nth-child(odd) {
  background-color: #f8f9fa;
}

.dropDownMenuMinWidth {
  min-width: 80px !important;
}

.dropDownMenu > div {
  background-color: transparent;
  border: none;
}

.headerDropdown {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 160px;
}

.attendancePopup .MuiPopover-paper {
  border-radius: 20px !important;
  border: 1px solid #d0d4d9 !important;
  background: #f1f3f5 !important;
  box-shadow: none !important;
}

.removeIconLessonBooksAlert .MuiAlert-icon {
  display: none;
}

.progressbar-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .reading-paragraph-img {
    min-width: 550px;
  }

  .progressbar-block {
    max-width: 300px;
    position: relative;
  }

  .progressbar-block:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 70px;
    top: 0;
    left: -45px;
    z-index: 2;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }

  .progressbar-block::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 70px;
    z-index: -1;
    transform: rotate(180deg);
    top: 0;
    right: 45px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
}

.MuiDialog-container {
  width: 100vw !important;
  height: 100vh !important;
}

.englishStyles {
  direction: ltr !important;
}

.profile-block > div {
  padding-right: 14px !important;
}

.profile-block > svg,
.course-dropdown svg {
  color: #e74c3c;
}

.course-dropdown {
  width: auto;
  min-width: 140px;
}

.course-dropdown > div {
  cursor: pointer;
}

.course-dropdown:focus,
.course-dropdown:hover,
.course-dropdown > div:focus,
.course-dropdown > div:hover {
  border: 0 !important;
  box-shadow: none !important;
}

.course-dropdown > div > div {
  padding-left: 0;
}

.course-dropdown > div > div > div {
  padding-right: 10px;
}

.course-dropdown span,
.course-dropdown > div span,
.apexcharts-datalabels-group {
  display: none !important;
}

.main-menu-link {
  margin: 0 22px;
}

.main-menu-link button {
  padding: 0;
}

.tabs-menu > div > div {
  display: inline-block;
  vertical-align: bottom;
  overflow-x: auto;
  scrollbar-width: thin;
  width: 100%;
  text-align: center;
}

.tabs-menu > div > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

.tabs-menu > div > div::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.tabs-menu > div > div::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border: 0;
}

.radial-chart-wrap .apexcharts-legend-marker {
  margin-left: 5px;
}

.radial-chart-wrap .apexcharts-legend {
  overflow: hidden;
  padding: 0;
}

.radial-chart-wrap
  .apexcharts-legend-series:last-child
  .apexcharts-legend-marker {
  margin-right: 5px;
}

.radial-chart-wrap .apexcharts-legend-text {
  font-size: 13px !important;
}

.onboarding-form .MuiSelect-select svg,
.onboarding-form .MuiInputBase-formControl svg {
  left: 5px !important;
  right: auto !important;
}

.breadcrumb-library span:last-child {
  display: none;
}

.mobileBackground {
  width: 100%;
}

.dropDownMenu.course-dropdown > div > div > div {
  font-size: 16px;
}

.dropDownMenu.course-dropdown > span + div > div > div {
  color: #878e95 !important;
}

.select-dropdown > div:first-child {
  padding-right: 8px !important;
}
@media (min-width: 1440px) {
  .student-dashboard-lesson table td:nth-child(2) {
    min-width: 400px;
  }
}

@media (min-width: 900px) and (max-width: 1400px) {
  .main-menu-link {
    margin: 0 16px;
  }
}

@media (min-width: 900px) and (max-width: 1099px) {
  .custom-container {
    max-width: 830px;
    padding-right: 60px;
  }
  .custom-containers-style {
    max-width: 830px;
    padding-right: 60px;
  }
  .main-menu-link {
    margin: 0 10px;
  }
}

@media (min-width: 1100px) and (max-width: 1199px) {
  .custom-container {
    max-width: 1080px;
    padding-right: 60px;
  }
  .custom-containers-style {
    max-width: 1080px;
    padding-right: 60px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .custom-container {
    max-width: 1170px;
    padding-right: 60px;
  }
  .custom-containers-style {
    max-width: 1170px;
    padding-right: 60px;
  }
}

@media (min-width: 1300px) and (max-width: 1599px) {
  .custom-container {
    max-width: 1230px;
    padding-right: 60px;
  }
  .custom-containers-style {
    max-width: 95%;
  }
}

@media (min-width: 1600px) {
  .custom-container {
    max-width: 95%;
  }
  .custom-containers-style {
    max-width: 95%;
  }
}

@media (max-width: 900px) {
  .course-dropdown {
    max-width: 70vw;
    margin: 0 5px 7px;
  }

  .course-dropdown > div,
  .react-select-3-lightbox {
    background-color: #fff !important;
    max-width: max-content;
  }

  .onboarding-form {
    margin-top: 15px;
  }

  .mobileBackground {
    width: 100% !important;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(8, 15, 52, 0.05);
    padding: 0 14px 15px 0;
    border-radius: 8px;
    margin-bottom: 15px;
  }

  .mobileBackground + .mobileBackground,
  .mobileBackground:last-child {
    margin-bottom: 8px;
  }
}

/* Define the animation keyframes */
@keyframes moveImage {
  0% {
    bottom: 280px;
  }

  100% {
    bottom: 90px;
  }
}

.celebration-graphics {
  transition: bottom 1s ease;
  /* Define the animation transition */
  animation: moveImage 3s forwards;
  /* Run the animation infinitely on hover */
}

/* Define the animation keyframes */
@keyframes moveImageTop {
  0% {
    top: -20px;
  }
  100% {
    top: 20px;
  }
}

.session-celebration-graphics {
  transition: top 1s ease; /* Define the animation transition */
  animation: moveImageTop 1.5s forwards; /* Run the animation infinitely on hover */
}

.progress-circle {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 80px;
  top: 40px;
}

.progress-circle .svg {
  transform: rotate(-90deg);
}

@media (max-width: 767px) {
  .progress-circle {
    width: 116px;
    height: 116px;
    left: 70px;
    top: 10px;
  }
  .progress-circle .svg {
    transform: scale(0.73);
  }
  .progress-circle .svg + div {
    max-width: 60px;
    top: -136px;
    left: -70px;
  }
  .celebration-graphics {
    max-width: 440px;
    margin-bottom: 0;
    top: -2px;
  }
  .modal-session-content-img img {
    right: -30px;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .octopus-dumbbell {
    margin-bottom: 10px !important;
  }
}

.progress {
  fill: #fff;
  stroke-width: 11;
  stroke-dasharray: 502;
  /* Circumference of the circle (2 * π * radius) */
  stroke-dashoffset: 502;
  /* Hide initially by setting dashoffset equal to dasharray */
  stroke-linecap: round;
  stroke: red;
  animation: progressAnimation 3s forwards;
}

@keyframes progressAnimation {
  0% {
    stroke-dashoffset: 502;
    /* Start with full circle */
    stroke: red;
    /* Start with red color */
  }

  100% {
    stroke-dashoffset: 0;
    /* Completely fill the circle */
    stroke: green;
    /* End with green color */
  }
}

.react-joyride__tooltip > div > div {
  padding: 10px 0 0 !important;
}

.react-joyride__tooltip > button {
  display: none;
}

.calendar-input input[type="date"] {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  text-align: end;
}
.fc-event-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.custom-tooltip {
  position: relative;
  background-color: #fff;
  color: #333;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

.custom-tooltip-rate {
  color: #bf9d13;
  padding-top: 5px;
  font-weight: 600;
}

.custom-tooltip .arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -10px;
  left: -30px;
}

.custom-accordion > div {
  background-color: #f8f9fa;
}
.custom-accordion .custom-accordion > div {
  background-color: #f1f3f5;
}
.custom-accordion .custom-accordion .custom-accordion > div {
  background-color: #e8ebed;
}
/* .custom-accordion .custom-accordion .custom-accordion .custom-accordion > div {
  background-color: #e1e4e6;
}
.custom-accordion .custom-accordion .custom-accordion .custom-accordion .custom-accordion > div {
  background-color: #dee0e1;
} */
/* .custom-accordion:nth-of-type(2) > div {
  background-color: #f8f9fa;
} */

.custom-accordion-research > div {
  background-color: #fff;
}
.custom-accordion-research .custom-accordion-research > div {
  background-color: #f8f9fa;
}
.custom-accordion-research
  .custom-accordion-research
  .custom-accordion-research
  > div {
  background-color: #f1f3f5;
}
.custom-accordion-research .custom-accordion-research > div + div,
.custom-accordion-research
  .custom-accordion-research
  .custom-accordion-research
  .custom-accordion-research
  > div {
  background-color: #e8ebed !important;
}
.meeting-child > div:first-child {
  display: none;
}
.breadcrumb-library-session a:last-of-type,
.breadcrumb-library-topic-detail a:last-of-type {
  pointer-events: none;
  cursor: default;
}

.e-pv-sidebar-toolbar {
  z-index: 0;
}

.board-exam-explanation fieldset {
  display: none;
}
