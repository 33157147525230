.image-template-component-wrapper .MuiButtonBase-root {
    transition: all ease 0.3s !important;
    margin-right: 0;
}

.image-template-component-wrapper .MuiButtonBase-root:hover {
    margin-right: 0;
}

.login-title {
    width: 100%;
    display: flex;
    justify-content: center;
}

.arrowicon {
    transform: rotate(180deg);
    border: 1px solid purple;
    border-radius: 7px;

}

@media (min-width: 900px) {
    .login-title {
        justify-content: flex-start;
    }
}